import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Үнэт цаас шинээр бүртгүүлэх",
  "description": null,
  "author": "OTC help",
  "category": "security-applications",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Үнэт цаас шинээр бүртгүүлэх`}</h2>
    <ol>
      <li parentName="ol">{`Платформд зөвхөн андеррайтер эрх бүхий хэрэглэгч үнэт цаас бүртгүүлэх эрхтэй байна.`}</li>
      <li parentName="ol">{`Үндсэн цэсний `}<strong parentName="li">{`[Security Applications]`}</strong>{` хэсэгт нэвтрэн шаардлагатай мэдээллүүдийг бөглөж холбогдох баримт бичгийг хавсаргана:`}</li>
    </ol>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[Type]`}</strong>{` үнэт цаасны төрлийг сонгоно`}</li>
      <li parentName="ul">{`Бондын төрлийг сонгосон тохиолдолд `}<strong parentName="li">{`[Securities Brief Info.]`}</strong>{` буюу үнэт цаасны товч мэдээлэл хэсэгт:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Issuer]`}</strong>{` хэсэгт үнэт цаас гаргагчийн оноосон нэр`}</li>
          <li parentName="ul"><strong parentName="li">{`[Securities Short Name]`}</strong>{` Үнэт цаасны үсгэн код`}</li>
          <li parentName="ul"><strong parentName="li">{`[Issue Price]`}</strong>{` үнэт цаасны нэгжийн үнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Issue Quantity]`}</strong>{` үнэт цаасны тоо ширхэг`}</li>
          <li parentName="ul"><strong parentName="li">{`[Offering Amount]`}</strong>{` хэсэгт үнэт цаасны нийт үнийн дүнг өмнө оруулсан үнэ болон дүнгийн мэдээллээр системээр тооцоолон үзүүлнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Yield]`}</strong>{` хүүгийн хэмжээ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Securities Type]`}</strong>{` үнэт цаасны төрөл`}</li>
          <li parentName="ul"><strong parentName="li">{`[Coupon Period]`}</strong>{` хүү төлөх хугацаа`}</li>
          <li parentName="ul"><strong parentName="li">{`[Primary Offer Start Date]`}</strong>{` үнэт цаасыг арилжиж эхлэх огноо`}</li>
          <li parentName="ul"><strong parentName="li">{`[Primary Offer Close Date]`}</strong>{` үнэт цаасны арилжааг хаах огноо`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Хувьцааны төрлийг сонгосон тохиолдолд `}<strong parentName="li">{`[Securities Brief Info.]`}</strong>{` буюу үнэт цаасны товч мэдээлэл хэсэгт:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Issuer]`}</strong>{` хэсэгт үнэт цаас гаргагчийн оноосон нэр`}</li>
          <li parentName="ul"><strong parentName="li">{`[Offering Price]`}</strong>{` санал болгох нэгжийн үнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Offering Quantity]`}</strong>{` санал болгох тоо ширхэг`}</li>
          <li parentName="ul"><strong parentName="li">{`[Total Offering Amount]`}</strong>{` хэсэгт үнэт цаасны нийт үнийн дүнг өмнө оруулсан үнэ болон тоо ширхгийн мэдээллээр системээр тооцоолон үзүүлнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Offering Percentage]`}</strong>{` санал болгож буй хувь`}</li>
          <li parentName="ul"><strong parentName="li">{`[Securities Type]`}</strong>{` үнэт цаасны төрөл`}</li>
          <li parentName="ul"><strong parentName="li">{`[Coupon Period]`}</strong>{` хүү төлөх хугацаа`}</li>
          <li parentName="ul"><strong parentName="li">{`[Primary Offer Start Date]`}</strong>{` анхдагч зах зээлийн арилжааг эхлэх огноо`}</li>
          <li parentName="ul"><strong parentName="li">{`[Primary Offer Close Date]`}</strong>{` анхдагч зах зээлийн арилжааг хаах огноо`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`[Additional Info]`}</strong>{` буюу нэмэлт мэдээлэл хэсэг:`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Co-underwriter]`}</strong>{` туслах андеррайтерын нэр`}</li>
          <li parentName="ul"><strong parentName="li">{`[Min Trading Quantity]`}</strong>{` арилжааны хамгийн бага тоо ширхэг`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`[Upload Files]`}</strong>{` буюу баримт бичиг хавсаргах хэсэгт Биржийн бус зах зээлийн үнэт цаасны бүртгэлийн журмын дагуу бүрдүүлэх баримт бичгийг хавсаргана:`}
        <ul parentName="li">
          <li parentName="ul">{`Төслийн танилцуулга, Мэргэжлийн байгууллагын дүгнэлт, албан бичиг, маягт 2001, маягт 2002, маягт 2003 болон бусад материалууд багтана`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Дээрх шаардлагатай мэдээллүүдийг оруулан `}<strong parentName="p">{`[Save]`}</strong>{` товч дарснаар бүртгүүлэх хүсэлт `}<strong parentName="p">{`[Security Applications]`}</strong>{` - `}<strong parentName="p">{`[List of Security Applications]`}</strong>{` жагсаалтад хадгалагдах болно. Тус жагсаалтаас бүртгэлийн хүсэлтийн төлөв болон хураангуй мэдээллийг харах боломжтой`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Бүртгэлийн хүсэлтийн баруун буланд `}<strong parentName="p">{`[Action]`}</strong>{` товчийг дарж `}<strong parentName="p">{`[Submit]`}</strong>{` буюу үнэт цаас бүртгүүлэх хүсэлтийг Бүртгэлийн хороонд илгээх, `}<strong parentName="p">{`[Update]`}</strong>{` буюу хүсэлтийн мэдээллийг шинэчлэх, `}<strong parentName="p">{`[View]`}</strong>{` буюу оруулсан үнэт цаасны мэдээллийг платформд байршсан байдлаар харах, `}<strong parentName="p">{`[Delete]`}</strong>{` хүсэлтийг устгах гэсэн үйлдлүүдээс сонгоно.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`[Submit]`}</strong>{` дарж илгээсэн хүсэлтийг Бүртгэлийн хороо Биржийн бус зах зээлийн үнэт цаасны бүртгэлийн журмын дагуу хянан шалгаж системээр зөвшөөрөл олгоно.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      